import React, { useEffect, useContext } from 'react'
import { GameContext } from '../../context/GameContext';

export default function GameCodeDisplay({ socket, localGameCode, setLocalGameCode, setGameStarted }) {
    const { startGame, players, setPlayers } = useContext(GameContext)
    
    useEffect(() => {

        const generateGameCode = () => {
          const code = Math.random().toString(36).substr(2, 6).toUpperCase();
          setLocalGameCode(code);
          socket.emit('createGame', code);
        };

        if (!localGameCode) {
            generateGameCode();
        }
    
        socket.on('gameCreated', (code) => {
          console.log(`Game created with code: ${code}`);
        });
    
        socket.on('updatePlayerList', (updatedPlayers) => {
          console.log("NEW PLAYER!!!", updatedPlayers);
          setPlayers(updatedPlayers);
        });

        socket.on('gameStarted', ({ gameInfo, question }) => {
            console.log("GAME STARTED!", question)
            startGame(socket, localGameCode, gameInfo, question);
            setGameStarted(true);
        });

        return () => {
            socket.off('gameCreated');
            socket.off('updatePlayerList');
            socket.off('gameStarted')
        };

    }, [ localGameCode ])
    return (
    <div>
        <h1>Host Game</h1>
          <p>Your Game Code: {localGameCode}</p>
          <h2>Players Joined:</h2>
          <ul>
            {players.map(player => (
              <li key={player.id}>{player.name}</li>
            ))}
          </ul>
    </div>
  )
}
