// src/views/WaitingRoom.js
import React from 'react';

const WaitingRoom = () => {

  return (
    <div>
      <h1>Waiting Room</h1>
      <p>Please wait for other players to submit their responses...</p>
    </div>
  );
};

export default WaitingRoom;
