import React, { useState, useContext, useEffect } from 'react';
import { GameContext } from '../../context/GameContext';
import { BarChart } from '@mui/x-charts';

export default function ChartDisplay() {
  const { players } = useContext(GameContext);
  const [drinks, setDrinks] = useState([]);
  const [shots, setShots] = useState([]);
  const [playerNames, setPlayerNames] = useState([]);

  useEffect(() => {
    // Extract player names and drinks from the players array
    const names = players.map(player => player.name);
    const drinksTaken = players.map(player => player.drinksTaken);
    const shotsTaken = players.map(player => player.shotsTaken);

    // Update the state with the extracted data
    setPlayerNames(names);
    setDrinks(drinksTaken);
    setShots(shotsTaken)
  }, [players]); // Dependency array to re-run when players data changes

  return (
    <div className='charts'>
      <BarChart
        xAxis={[
          {
            id: 'barCategories',
            data: playerNames, // Use the state variable for player names
            scaleType: 'band',
          },
        ]}
        series={[
          {data: drinks, label: "Drinks"},
          {data: shots, label: "Shots"},
        ]}
        width={500}
        height={300}
      />
    </div>
  );
}
