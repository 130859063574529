import React, { useContext, useEffect, useState } from 'react';
import { GameContext } from '../../context/GameContext';

export default function LeaderBoard() {
  const [sortedPlayers, setSortedPlayers] = useState([]);
  
  const { players, responses } = useContext(GameContext);

  useEffect(() => {
    // Sort players by score in descending order
    const sorted = [...players].sort((a, b) => b.score - a.score);
    setSortedPlayers(sorted);
  }, [players]);

  const topThree = sortedPlayers.slice(0, 3);
  const remainingPlayers = sortedPlayers.slice(3);

  return (
    <div className="leaderboard">
      <div className="podium">
        {topThree.map((player, index) => (
          <div key={index} className={`podium-item podium-${index + 1}`}>
            <h2>{index + 1}. {player.name}</h2>
            <p>{player.score} pts</p>
          </div>
        ))}
      </div>
      <div className="player-list">
        {remainingPlayers.map((player, index) => (
          <div key={index} className="player-item">
            <span>{index + 4}. {player.name}</span>
            <span>{player.score} pts</span>
          </div>
        ))}
      </div>
    </div>
  );
}
