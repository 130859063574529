// src/views/VotingPage.js
import React, { useState, useEffect, useContext } from 'react';
import { GameContext } from '../../context/GameContext';
import WaitingRoom from './WaitingRoom';

const VoteToDrink = ( { responses, setVoted }) => {
  const { gameCode, user, players, currentQuestion, socket } = useContext(GameContext);
  const [isCorrect, setIsCorrect] = useState(false)

  useEffect(() => {
    console.log(responses)
    
    console.log('correct?', responses[socket.id].response === currentQuestion["Answer"].correct_answer)
    console.log('ScoreTrack:', user.scoreTrack)
    setIsCorrect(responses[socket.id].response === currentQuestion["Answer"].correct_answer)

  }, []);

  const handleVote = (playerId, isShot) => {
    console.log('handling Vote', playerId, gameCode)
    socket.emit('vote', { gameCode, votedFor: playerId, isShot});
    setVoted(true);
  };

  const handleDrinkTaken = (isShot) => {
    socket.emit('drinkTaken', { gameCode, isShot: isShot })
    setVoted(true)
  }
  
  if (user.scoreTrack === 5) {
    return (
      <>
        <h1>CORRECT!!!</h1>
        <h2>3 IN A ROW!</h2>
        <h2>Choose who you want to take a SHOT:</h2>
        {players.map((player, index) => (
          player.id === socket.id ? (
            <></>
          ) : (
            <button key={index} onClick={() => handleVote(player.id, true)}>
              {player.name}
            </button>
          )
        ))}
      </>
    )
  }
  else if (user.scoreTrack === -5) {
    return (
      <>
        <h1>INCORRECT!!!</h1>
        <h1>5 IN A ROW</h1>
        <h2>Take a SHOT!</h2>
        <p>get fuuucked</p>
        <button onClick={(() => {handleDrinkTaken(true)})}>Sip Taken</button>
      </>
    );
  } else if (isCorrect) {
    return (
      <>
        <h1>CORRECT!!!</h1>
        <h2>Choose who you want to take a sip:</h2>
        {players.map((player, index) => (
          player.id === socket.id ? (
            <></>
          ) : (
            <button key={index} onClick={() => handleVote(player.id, false)}>
              {player.name}
            </button>
          )
        ))}
      </>
    )
  } else {
    return (
        <>
          <h1>INCORRECT!!!</h1>
          <h2>Take a sip!</h2>
          <p>get fuuucked</p>
          <button onClick={(() => {handleDrinkTaken(false)})}>Sip Taken</button>
        </>
    );
  }
};

export default VoteToDrink;
