import React, { useEffect, useContext, useState } from 'react';
import { GameContext } from '../../context/GameContext';

export default function VoteDisplay({ votes, resetToNextQuestion }) {
    const [message, setMessage] = useState('');
    const [needsToAccept, setNeedsToAccept] = useState(true);
    const { players, user, gameCode, socket } = useContext(GameContext);

    useEffect(() => {
        socket.on('allDrank', ({ nextQuestion }) => {
            console.log("All drank!")
            setTimeout(() => {
                resetToNextQuestion(nextQuestion);
            }, 1000);
        });

        console.log('votes:', votes);
        console.log('players', players);
        const name = user.name;

        // Extract voters and determine if it's a shot or sip
        const voters = Object.keys(votes).filter(key => votes[key].target === name);
        const shots = voters.filter(key => votes[key].isShot);
        const sips = voters.filter(key => !votes[key].isShot);

        // Create the message based on voters and their vote type (shot or sip)
        if (voters.length === 1) {
            const voteType = votes[voters[0]].isShot ? '1 SHOT' : '1 sip';
            setMessage(`${voters[0]} voted for you, take ${voteType}`);
            setNeedsToAccept(true);
        } else if (voters.length === 2) {
            const shotText = shots.length > 0 ? `${shots.length} SHOT${shots.length > 1 ? 's' : ''}` : '';
            const sipText = sips.length > 0 ? `${sips.length} sip${sips.length > 1 ? 's' : ''}` : '';
            const combinedText = [shotText, sipText].filter(Boolean).join(' and ');
            setMessage(`${voters[0]} and ${voters[1]} voted for you, take ${combinedText}`);
            setNeedsToAccept(true);
        } else if (voters.length > 2) {
            const lastVoter = voters.pop();
            const shotText = shots.length > 0 ? `${shots.length} SHOT${shots.length > 1 ? 's' : ''}` : '';
            const sipText = sips.length > 0 ? `${sips.length} sip${sips.length > 1 ? 's' : ''}` : '';
            const combinedText = [shotText, sipText].filter(Boolean).join(' and ');
            setMessage(`${voters.join(', ')}, and ${lastVoter} voted for you, take ${combinedText}`);
            setNeedsToAccept(true);
        } else {
            setMessage('No one voted for you... this time');
            setNeedsToAccept(false);
        }

        return () => {
            socket.off('allDrank');
        };
    }, [votes, players, user.name, resetToNextQuestion, socket]);

    const handleDrinkTaken = () => {
        const name = user.name;
        const voters = Object.keys(votes).filter(key => votes[key].target === name);
        socket.emit('votedDrinkTaken', { gameCode, drinks: voters.length });
        setMessage('Well Done');
        setNeedsToAccept(false);
    };

    return (
        <>
            <p>{message}</p>
            {needsToAccept && <button onClick={handleDrinkTaken}>Done</button>}
        </>
    );
}
