import React, { useEffect, useContext } from 'react'
import { GameContext } from '../../context/GameContext';

export default function GameJoined({gameCodeInput, isFirstPlayer}) {
    const { startGame, socket } = useContext(GameContext);

    const handleStartGame = () => {
        socket.emit('startGame', gameCodeInput);
    };

    useEffect(() => {
        socket.on('gameStarted', ({ gameInfo, question }) => {
            console.log("GAME STARTED")
            startGame(socket, gameCodeInput, gameInfo, question);
        });

        return() => {
            socket.off('gameStarted');
        }
    }, [startGame])

    return (
        <>
            <h1>Game joined</h1>
            <p>Waiting for the game to start...</p>
            {isFirstPlayer && (
                <button onClick={handleStartGame}>Everyone's in</button>
            )}
        </>
    )
}
