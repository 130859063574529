import React, { useState, useEffect, useContext } from 'react';
import { GameContext } from '../../context/GameContext';

const rules = [
    {
        rule: "Welcome to Donald's Trivia Night",
        duration: 1,
        audio: 'HelloAndWelcome.mp3'
    },
    {
        rule: "You will be shown 100 multiple choice questions. Answer them on your device",
        duration: 1,
        audio: '100 multi.mp3'
    },
    {
        rule: "If you get it wrong, take a sip of your drink",
        duration: 1,
        audio: 'wrong.mp3'
    },
    {
        rule: "If you get it right, vote for who should take an extra sip",
        duration: 1.5,
        audio: 'right.mp3'
    },
    {
        rule: "If you get 5 in a row wrong, take a shot",
        duration: 1,
        audio: '5 wrong.mp3'
    },
    {
        rule: "If you get 3 in a row right, vote for who should take a shot",
        duration: 1,
        audio: '3 right.mp3'
    },
    {
        rule: "Good luck...",
        duration: 1,
        audio: 'GLHF.mp3'
    },
    {
        rule: "Anyone who was here after 7pm, take a shot",
        duration: 1,
        audio: "LateShot.mp3"
    }
];

export default function RulesDisplay({ socket, rulesFinished, setRulesFinished }) {
    const [currentRuleIndex, setCurrentRuleIndex] = useState(0);
    const { gameCode } = useContext(GameContext);

    useEffect(() => {
        if (currentRuleIndex < rules.length) {
            // Play the audio for the current rule
            const audio = new Audio(`/audio/${rules[currentRuleIndex].audio}`);
            audio.play();

            // Set a timer for the next rule
            const timer = setTimeout(() => {
                setCurrentRuleIndex(currentRuleIndex + 1);
            }, rules[currentRuleIndex].duration * 1000);

            return () => clearTimeout(timer);
        } else if (currentRuleIndex >= rules.length) {
            // Emit that rules are finished
            console.log("EMITTING RULES FINISHED", gameCode);
            socket.emit('RulesFinished', gameCode);
            setRulesFinished(true);
        }
    }, [currentRuleIndex]);

    if (rulesFinished) {
        return <div></div>;
    }

    return (
        <div>
            <p>{rules[currentRuleIndex]?.rule}</p>
        </div>
    );
}
