import React, { useEffect, useContext } from 'react'
import { GameContext } from '../../context/GameContext'

export default function Rules({ setRulesFinished }) {
    const { socket } = useContext(GameContext)

    useEffect(() => {
        console.log("RULESFINISHED ON")
        socket.on('RulesFinishedNotif', () => {
            console.log("RULES FINISHED")
            setRulesFinished(true);
        });

        return() => {
            socket.off('RulesFinished');
        }
    }, [])

  return (
    <div>Look at the screen to see the rules donny</div>
  )
}
