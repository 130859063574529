// src/views/EnterResponse.js
import React, { useContext } from 'react';
import { GameContext } from '../../context/GameContext';


const EnterResponse = ({setSubmitted}) => {
  
  const { gameCode, currentQuestion, socket } = useContext(GameContext);
  const options = currentQuestion["Answer"]["options"]


  const handleSubmit = (response) => {
    console.log("SUBMITTED!!!", response)
    socket.emit('submitResponse', { gameCode, response });
    setSubmitted(true);
  };

  return (
      <>
        <h1>Enter Response</h1>
        {options.map((option, index) => (
            <button key={index} onClick={() => {handleSubmit(option)}}>{option}</button>
          ))}
      </>
  );
};

export default EnterResponse;
