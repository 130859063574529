import React, { useState, useEffect, useContext } from 'react';
import { GameContext } from '../../context/GameContext';
import HostWaiting from './HostWaiting';
import VoteOptions from './VoteOptions';
import GameCodeDisplay from './GameCodeDisplay';
import RulesDisplay from './RulesDisplay';
import TheEnd from './TheEnd';
import LeaderBoard from './LeaderBoard';
import ChartDisplay from './ChartDisplay';

const HostGame = () => {
  const [localGameCode, setLocalGameCode] = useState('');
  const [gameStarted, setGameStarted] = useState(false);
  const [allResponsesSubmitted, setAllResponsesSubmitted] = useState(false);
  const [rulesFinished, setRulesFinished] = useState(false);
  const [gameEnded, setGameEnded] = useState(false);
  const [gameStates, setGameStates] = useState({});
  const [isMuted, setIsMuted] = useState(false);
  const { setResponses, setPlayers, setCurrentQuestion, socket } = useContext(GameContext);

  // Store a single Audio object in the state
  const [audioPlayer, setAudioPlayer] = useState(new Audio('/audio/ql.mp3'));

  useEffect(() => {
    // Set initial audio properties
    audioPlayer.loop = true;
    audioPlayer.volume = 0.1;

    // Play the initial audio
    const playInitialAudio = async () => {
      try {
        if (!isMuted) {
          await audioPlayer.play();
        }
      } catch (err) {
        console.error('Failed to play ql.mp3:', err);
      }
    };

    playInitialAudio();

    // Cleanup on component unmount
    return () => {
      if (audioPlayer) {
        audioPlayer.pause();
        audioPlayer.currentTime = 0;
      }
    };
  }, [audioPlayer, isMuted]);

  useEffect(() => {
    // Handle transition to kahoot.mp3 when rules are finished
    if (rulesFinished) {
      // Pause and reset the current audio
      audioPlayer.pause();
      audioPlayer.currentTime = 0;

      // Update the source and play the new audio
      const newAudio = new Audio('/audio/kahoot.mp3');
      newAudio.loop = true;
      newAudio.volume = 0.1;
      setAudioPlayer(newAudio);

      if (!isMuted) {
        newAudio.play();
      }
    }
  }, [rulesFinished, isMuted]);

  const toggleMute = () => {
    setIsMuted((prevIsMuted) => {
      // Handle muting/unmuting the current audio
      if (prevIsMuted) {
        audioPlayer.muted = false;
        audioPlayer.play();
      } else {
        audioPlayer.muted = true;
      }
      return !prevIsMuted;
    });
  };

  const resetToNextQuestion = (responses, players) => {
    setAllResponsesSubmitted(true);
    setResponses(responses);
    setPlayers(players);
  };

  useEffect(() => {
    const handleAllResponsesSubmitted = ({ responses, players }) => {
      resetToNextQuestion(responses, players);
    };

    const handleAllDrank = ({ nextQuestion }) => {
      setTimeout(() => {
        setAllResponsesSubmitted(false);
        setResponses([]);
        setCurrentQuestion(nextQuestion);
      }, 1000);
    };

    socket.on('allResponsesSubmitted', handleAllResponsesSubmitted);
    socket.on('allDrank', handleAllDrank);
    socket.on('noneCorrect', handleAllDrank);
    socket.on('drinkDrunk', ({ players }) => {
      setPlayers(players);
    });

    socket.on('gameEnded', ({ players, gameStates }) => {
      setGameStates(gameStates);
      setPlayers(players);
      setGameEnded(true);
    });

    return () => {
      socket.off('allResponsesSubmitted', handleAllResponsesSubmitted);
      socket.off('allDrank', handleAllDrank);
    };
  }, []);

  if (gameEnded) {
    return (
      <TheEnd gameStates={gameStates} />
    );
  }

  return (
    <div className='hostGame'>
      {gameStarted && rulesFinished && allResponsesSubmitted && (
        <div className='container'>
          <h2>Leaderboard</h2>
          <LeaderBoard />
        </div>
      )
      }
      <div className='container'>
        <button onClick={toggleMute} style={{ position: 'absolute', top: '10px', left: '10px' }}>
          {isMuted ? 'Unmute' : 'Mute'}
        </button>
        {!gameStarted ? (
          <GameCodeDisplay socket={socket} localGameCode={localGameCode} setLocalGameCode={setLocalGameCode} setGameStarted={setGameStarted} />
        ) : (
          !rulesFinished ? (
            <RulesDisplay socket={socket} rulesFinished={rulesFinished} setRulesFinished={setRulesFinished} />
          ) : (
            !allResponsesSubmitted ? (
              <HostWaiting />
            ) : (
              <VoteOptions />
            )
          )
        )}
      </div>
      {gameStarted && rulesFinished && allResponsesSubmitted && (
        <>
          <div className='container'>
            <h2>Drink count</h2>
            <ChartDisplay />
          </div>
        </>
      )}
    </div>
  );
};

export default HostGame;
