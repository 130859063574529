// src/views/JoinGame.js
import React, { useState, useEffect, useContext } from 'react';
import EnterCode from './EnterCode';
import GameJoined from './GameJoined';
import { GameContext } from '../../context/GameContext';
import EnterResponse from './EnterResponse';
import Rules from './Rules';
import WaitingRoom from './WaitingRoom';
import VoteToDrink from './VoteToDrink';
import VoteDisplay from './VoteDisplay';
import TheEnd from './TheEnd';

const JoinGame = () => {
  const { socket, assignedPrompts, gameStarted, setCurrentQuestion, setPlayers } = useContext(GameContext);
  const [gameJoined, setGameJoined] = useState(false);
  const [isFirstPlayer, setIsFirstPlayer] = useState(false);
  const [gameCodeInput, setGameCodeInput] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [rulesFinished, setRulesFinished] = useState(false)
  const [allSubmitted, setAllSubmitted] = useState(false);
  const [voted, setVoted] = useState(false)
  const [allVoted, setAllVoted] = useState(false);
  const [votes, setVotes] = useState({})
  const [responses, setResponses] = useState([])
  const [gameEnded, setGameEnded] = useState(false)

  useEffect(() => {
    console.log('Prompts:', assignedPrompts)
  }, [assignedPrompts])

  const resetToNextQuestion = (nextQuestion) => {
    console.log("RESETTING TO THE NEXT QUESTION")
    setCurrentQuestion(nextQuestion)
    setVotes({})
    setResponses([])
    setSubmitted(false);
    setAllSubmitted(false);
    setVoted(false)
    setAllVoted(false)
  }

  useEffect(() => {

    socket.on('allResponsesSubmitted', ({ responses, players }) => {
      console.log("All responses Submitted", responses)
      setResponses(responses);
      setPlayers(players)
      setAllSubmitted(true)
    });

    socket.on('allVoted', ({ votes }) => {
      console.log('All voted!')
      setVotes(votes)
      setAllVoted(true)
    });

    socket.on('gameEnded', () => {
      console.log("GAME ENDED")
      setGameEnded(true)
    })

    socket.on('noneCorrect', ({nextQuestion}) => {
        resetToNextQuestion(nextQuestion)
    })

    return () => {
      socket.off('allResponsesSubmitted');
    };
  }, [prompt]);

  if (gameEnded) {
    return (
      <TheEnd />
    )
  }

  return (
    <div className="container">
      {!gameJoined ? (
        // Starting Page
        <EnterCode setGameJoined={setGameJoined} setIsFirstPlayer={setIsFirstPlayer} setGameCodeInput={setGameCodeInput} gameCodeInput={gameCodeInput} />
      ) : (
        // Game joined but not started
        !gameStarted ? (
          <GameJoined gameCodeInput={gameCodeInput} isFirstPlayer={isFirstPlayer} />
        ) : (
          // Everyone's in
          !rulesFinished ? (
            <Rules setRulesFinished={setRulesFinished} />
          ) : (
            // Rules are finished
            !submitted ? (
              <EnterResponse setSubmitted={setSubmitted} />
            ) : (
              // User has submitted
              !allSubmitted ? (
                <WaitingRoom/>
              ) : (
                // All users have submitted
                !voted ? (
                  <VoteToDrink responses={responses} setVoted={setVoted} />
                ) : (
                  // User has voted/drank
                  !allVoted ? (
                    <WaitingRoom/>
                  ) : (
                    <VoteDisplay votes={votes} resetToNextQuestion={resetToNextQuestion} />
                  )
                )
              )
            )
          )
         )
      )}
    </div>
  );
};

export default JoinGame;
