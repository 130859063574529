// src/context/GameContext.js
import React, { createContext, useState, useEffect } from 'react';
import baseSocket from '../services/socket';

const GameContext = createContext();

const GameProvider = ({ children }) => {
  const [gameCode, setGameCode] = useState('');
  const [players, setPlayers] = useState([])
  const [currentQuestion, setCurrentQuestion] = useState({});
  const [responses, setResponses] = useState([]);
  const [gameStarted, setGameStarted] = useState(false);
  const [user, setUser] = useState({})
  const [socket, setSocket] = useState(baseSocket)

  const startGame = (socket, code, info, question) => {
    setGameStarted(true);
    setGameCode(code);
    console.log("Received question in context:", question)
    setCurrentQuestion(question);
    setPlayers(info)
    setUser(info.find(player => player.id === socket.id))
    console.log('players:', players)
  };

  useEffect(() => {
    setUser(players.find(player => player.id === socket.id))
  }, [players])

  return (
    <GameContext.Provider value={{ socket, setSocket, gameCode, players, setPlayers, setCurrentQuestion, user, currentQuestion, startGame, gameStarted, responses, setResponses }}>
      {children}
    </GameContext.Provider>
  );
};

export { GameContext, GameProvider };
