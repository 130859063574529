import React from 'react'
import ChartDisplay from './ChartDisplay'

export default function TheEnd({ gameStates }) {
  

  return (
    <div className='container'>
        <h1>The end!</h1>
        <p>Thanks for playing</p>
        <ChartDisplay />
    </div>
  )
}
