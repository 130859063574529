// src/views/HostWaiting.js
import React, { useContext } from 'react';
import { GameContext } from '../../context/GameContext';

const HostWaiting = () => {
  const { currentQuestion } = useContext(GameContext) 
  return (
    <div>
      <h1>{currentQuestion["Question"]["title"]}</h1>
      <p>{currentQuestion["Question"]["description"]}</p>
      {currentQuestion["Question"]["image"] && 
        (
          <img src={`/images/${currentQuestion["Question"]["image"]}`} alt='Donald/Will fucked up...' style={{maxWidth: '500px'}}/>
        )
      }
      
    </div>
  );
};

export default HostWaiting;
