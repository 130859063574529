// src/views/Home.js
import React from 'react';
import { Link } from 'react-router-dom';

const Home = () => {
  return (
    <div className='container'>
      <h1>Trivia Night</h1>
      <Link to="/join">
        <button>Join Game</button>
      </Link>
      <Link to="/host">
        <button>Host Game</button>
      </Link>
    </div>
  );
};

export default Home;
