// src/views/VoteOptions.js
import React, { useEffect, useState, useContext } from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { GameContext } from '../../context/GameContext';
import ChartDisplay from './ChartDisplay';
import LeaderBoard from './LeaderBoard';

const VoteOptions = () => {
  const { responses, setResponses, currentQuestion, setPlayers, socket } = useContext(GameContext);
  const [scores, setScores] = useState({});

  useEffect(() => {

    socket.on('updateScores', ({ scores }) => {
      setScores(scores);
    });

    socket.on('newVote', (votes) => {
      console.log("NEW VOTE!!!")
    });

    return () => {
      socket.off('updateScores');
      socket.off('newVote');
    };
  }, [setResponses]);

  return (
    <div>
      <h1>Correct Answer: {currentQuestion.Answer.correct_answer}</h1>
      <p>{currentQuestion.Answer.description}</p>
      {currentQuestion.Answer.image && (
        <img src={`/images/${currentQuestion.Answer.image}`} alt={'Donald/Will fucked up... Insert ' + currentQuestion.Answer.image + ' here'} style={{maxWidth: '500px'}}/>
      )}
    </div>
);
};

export default VoteOptions;
