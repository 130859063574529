import { useState, useEffect, useContext } from 'react'
import { GameContext } from '../../context/GameContext';


export default function EnterCode( {setGameJoined, setIsFirstPlayer, setGameCodeInput, gameCodeInput}) {
    const { socket } = useContext(GameContext)
    const [name, setName] = useState('');
    
    

    const handleJoin = () => {
        console.log("TRYING TO JOIN!!", name, gameCodeInput)
        socket.emit('joinRoom', gameCodeInput);  // Join the room with the game code
        socket.emit('joinGame', { name, gameCode: gameCodeInput });
    };

    useEffect(() => {
        socket.on('gameJoined', ({ firstPlayerId }) => {
            console.log("GAME JOINED!!!@")
            setIsFirstPlayer(socket.id === firstPlayerId);
            setGameJoined(true);
        });

        socket.on('joinError', (error) => {
            alert(error);
        });

        return () => {
            socket.off('gameJoined');
            socket.off('joinError');
        };
    }, [gameCodeInput]);

  return (
    <>
        <h1>Join Game</h1>
        <input
        type="text"
        placeholder="Your Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        />
        <input
        type="text"
        placeholder="Game Code"
        value={gameCodeInput}
        onChange={(e) => setGameCodeInput(e.target.value)}
        />
        <button onClick={handleJoin}>Join</button>
    </>
  )
}
