// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { GameProvider } from './context/GameContext';
import Home from './views/Home';
import JoinGame from './views/join/JoinGame';
import HostGame from './views/host/HostGame';
import EnterResponse from './views/join/EnterResponse';
import WaitingRoom from './views/join/WaitingRoom';
import HostWaiting from './views/host/HostWaiting';
import VoteOptions from './views/host/VoteOptions';
import VotingPage from './views/join/VoteToDrink';
import './styles.css'; // Import the CSS file

function App() {
  return (
    <GameProvider>
      <Router>
        <div className="App">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/join" element={<JoinGame />} />
            <Route path="/host" element={<HostGame />} />
            <Route path="/enter-response" element={<EnterResponse />} />
            <Route path="/waiting-room" element={<WaitingRoom />} />
            <Route path="/host-waiting" element={<HostWaiting />} />
            <Route path="/vote-options" element={<VoteOptions />} />
            <Route path="/voting" element={<VotingPage />} />
          </Routes>
        </div>
      </Router>
    </GameProvider>
  );
}

export default App;
